// extracted by mini-css-extract-plugin
export var addon = "Boilerplate-module--addon--38N-V";
export var addonIcon = "Boilerplate-module--addonIcon--3groU";
export var addonSubtitle = "Boilerplate-module--addonSubtitle--3eXwm";
export var addonTitle = "Boilerplate-module--addonTitle--LXJyI";
export var addons = "Boilerplate-module--addons--3qrpM";
export var addonsWrapper = "Boilerplate-module--addonsWrapper--zoYPV";
export var figure = "Boilerplate-module--figure--3EcH1";
export var figureImage = "Boilerplate-module--figureImage--Ge6js";
export var reversed = "Boilerplate-module--reversed--uUoZC";
export var step = "Boilerplate-module--step--17RJ3";
export var stepSubtitle = "Boilerplate-module--stepSubtitle--3tsl2";
export var stepText = "Boilerplate-module--stepText--1kEVa";
export var stepTitle = "Boilerplate-module--stepTitle--3NZY2";
export var subtitle = "Boilerplate-module--subtitle--3xhKE";
export var template = "Boilerplate-module--template--2UqjZ";
export var templateIcon = "Boilerplate-module--templateIcon--2jZ3J";
export var templateSubtitle = "Boilerplate-module--templateSubtitle--1Qj5e";
export var templateTitle = "Boilerplate-module--templateTitle--2M4Gp";
export var templates = "Boilerplate-module--templates--2hjy1";
export var templatesWrapper = "Boilerplate-module--templatesWrapper--RExnS";
export var title = "Boilerplate-module--title--16Wr5";