import React from "react"
import Row from "../../components/Row"
import SalesDashboard from "@src/icons/v2/sales-dashboard.svg"
import SlackApproval from "@src/icons/v2/slack-approval.svg"
import ClientOnboarding from "@src/icons/v2/client-onboarding.svg"
import TicketManagement from "@src/icons/v2/ticket-management.svg"
import LoginSignup from "@src/icons/v2/login-signup.svg"
import UILibraries from "@src/icons/v2/ui-libraries.png"

import * as styles from "./Boilerplate.module.scss"

const Boilerplate = () => {
  return (
    <Row style={{ zIndex: 3 }} className={styles.row}>
      <h2 className={styles.title}>Hit the ground running</h2>
      <p className={styles.subtitle}>
        With our library of resources, you never have to start from scratch
      </p>
      <Step
        title="Templates & Boilerplates"
        subtitle={
          <>
            Clone ready-to-go reference projects to get started quickly. We have
            a growing library of templates and boilerplates for you to use.
          </>
        }
      >
        <Templates />
      </Step>
      <Step
        title="Addons"
        reversed
        subtitle={
          <>
            Add common functionalities such as login, signup, ticket management
            etc. to your app with a single click.
          </>
        }
      >
        <Datasources />
      </Step>
      <Step
        title="UI Libraries & Theming"
        subtitle={
          <>
            Use popular UI libraries such as Material UI, Ant Design. Customize
            the look and feel of your app using theming.
          </>
        }
      >
        <figure className={styles.figure}>
          <img className={styles.figureImage} src={UILibraries} />
        </figure>
      </Step>
    </Row>
  )
}

function Step({ superscript, title, subtitle, children, reversed }) {
  return (
    <section className={`${styles.step} ${reversed ? styles.reversed : ""}`}>
      <div className={styles.stepText}>
        <h2 className={styles.stepTitle}>{title}</h2>
        <p className={styles.stepSubtitle}>{subtitle}</p>
      </div>
      {children}
    </section>
  )
}

function Templates({ title, description, href }) {
  const templates = [
    {
      title: "Client Onboarding",
      subtitle: "Create onboarding flows in a second.",
      icon: SalesDashboard,
    },
    {
      title: "Slack Approvals",
      subtitle: "Get approvals of any kind on Slack",
      icon: SlackApproval,
    },
    {
      title: "Sales Dashboard",
      subtitle: "Combine multiple channels for combined metrics",
      icon: ClientOnboarding,
    },
  ]

  return (
    <figure className={styles.templatesWrapper}>
      <ul className={styles.templates}>
        {[...templates, ...templates].map((template, index) => (
          <li className={styles.template} key={index}>
            <div className={styles.templateIcon}>
              <img src={template.icon} alt={template.title} />
            </div>
            <div className={styles.templateText}>
              <h4 className={styles.templateTitle}>{template.title}</h4>
              <p className={styles.templateSubtitle}>{template.subtitle}</p>
            </div>
          </li>
        ))}
      </ul>
    </figure>
  )
}

function Datasources({ title, description, href }) {
  const addons = [
    { title: "Login & Signup", subtitle: "Slack", icon: LoginSignup },
    { title: "Ticket Management", subtitle: "Slack", icon: TicketManagement },
    { title: "Another Addon", subtitle: "Slack", icon: SalesDashboard },
  ]
  return (
    <figure className={styles.addonsWrapper}>
      <ul className={styles.addons}>
        {addons.map((addon, index) => (
          <li className={styles.addon} key={index}>
            <div className={styles.addonIcon}>
              <img src={addon.icon} alt={addon.title} />
            </div>
            <div className={styles.addonText}>
              <h4 className={styles.addonTitle}>{addon.title}</h4>
            </div>
          </li>
        ))}
      </ul>
    </figure>
  )
}

export default Boilerplate
