// extracted by mini-css-extract-plugin
export var fragmentedData = "UseCases-module--fragmentedData--2VdtX";
export var row = "UseCases-module--row--2K7oR";
export var slidein = "UseCases-module--slidein--eLLbb";
export var subtitle = "UseCases-module--subtitle--1P1tB";
export var title = "UseCases-module--title--28Gm9";
export var useCase = "UseCases-module--useCase--20275";
export var useCaseIcon = "UseCases-module--useCaseIcon--3CLgM";
export var useCaseLink = "UseCases-module--useCaseLink--TnjT5";
export var useCaseSubtitle = "UseCases-module--useCaseSubtitle--2Hb5C";
export var useCaseTitle = "UseCases-module--useCaseTitle--M-VcQ";
export var useCases = "UseCases-module--useCases--1c1wE";
export var useCasesWrapper = "UseCases-module--useCasesWrapper--3l2pr";