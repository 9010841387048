import React from "react"
import Row from "../../components/Row"
import ShopifyProductSync from "@src/icons/v2/shopify-product-sync.svg"
import OKRDashboards from "@src/icons/v2/okr-dashboards.svg"
import ApprovalFlow from "@src/icons/v2/submit-for-approval.svg"
import SupportDesk from "@src/icons/v2/support-desk-new.svg"
import getAppUrl from "@src/utils/getAppUrl"

import * as styles from "./UseCases.module.scss"

const UseCases = ({ location }) => {
  return (
    <Row style={{ zIndex: 3 }} className={styles.row}>
      <h2 className={styles.title}>Build automations that increase ROI</h2>
      <p className={styles.subtitle}>
        Improve your ROI by decreasing time and complexity to build commonly
        used automations.
      </p>
      <section className={styles.fragmentedData}>
        <Datasources location={location} />
      </section>
    </Row>
  )
}

function Datasources({ location }) {
  const useCases = [
    {
      title: "Shopify Product Sync",
      icon: ShopifyProductSync,
      subtitle: "Sync multiple Shopify stores, add custom logic and more.",
    },
    {
      title: "OKR Dashboards",
      icon: OKRDashboards,
      subtitle:
        "Track OKRs dynamically powered by data across services & departments.",
    },
    {
      title: "Slack Approval Flow",
      icon: ApprovalFlow,
      subtitle:
        "Get approvals from teams and individuals on Slack. Carry out actions depending on approval or denail.",
    },
    {
      title: "Support Desk",
      icon: SupportDesk,
      subtitle: "Automate customer support by connecting your support tools.",
    },
  ]
  return (
    <figure className={styles.useCasesWrapper}>
      <ul className={styles.useCases}>
        {[...useCases, ...useCases, ...useCases].map((useCase, index) => (
          <li className={styles.useCase} key={index}>
            <div className={styles.useCaseIcon}>
              {useCase.icon ? <img src={useCase.icon} /> : null}
            </div>
            <div className={styles.useCaseText}>
              <h4 className={styles.useCaseTitle}>{useCase.title}</h4>
              <p className={styles.useCaseSubtitle}>{useCase.subtitle}</p>
              <a
                href={getAppUrl(location)}
                target="_blank"
                className={styles.useCaseLink}
              >
                Clone →
              </a>
            </div>
          </li>
        ))}
      </ul>
    </figure>
  )
}

export default UseCases
