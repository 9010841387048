import React from "react"

import Layout from "@src/components/Layout"
import Callout from "@src/components/Callout"
import OG from "@src/icons/og/og_home.v2.png"

import Splash from "./sections/Splash"
import UseCases from "./sections/UseCases"
import NoVendorLockIn from "./sections/NoVendorLockIn"
import ClientStrip from "./sections/ClientStrip"
import HowItWorks from "./sections/HowItWorks"
import Boilerplate from "./sections/Boilerplate"

const IndexPage = ({ location }) => {
  return (
    <Layout
      title="Canonic | Build internal tools for agile teams"
      description="The simplest way for agile teams to build internal tools. Focus on critical tasks and automate the rest."
      url="https://canonic.dev"
      location={location}
      ogImage={`https://canonic.dev${OG}`}
    >
      <Splash location={location} />
      <ClientStrip location={location} />
      <HowItWorks
        location={location}
        subtitle={"Connect your data, add your logic, and snap together UI"}
      />
      <UseCases location={location} />
      <Boilerplate location={location} />
      <NoVendorLockIn location={location} />
      <Callout location={location} title="homepage" />
    </Layout>
  )
}

export default IndexPage
