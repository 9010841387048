import React from "react"
import Row from "../../components/Row"
import Column from "../../../components/Column/Column"
import * as styles from "./NoVendorLockIn.module.scss"

import SelfHostedIcon from "@src/icons/vendor-lock-in/self-hosted.png"
import CodeExportIcon from "@src/icons/vendor-lock-in/code-export.png"
import BuiltInEditorIcon from "@src/icons/vendor-lock-in/built-in-editor.png"

const NoVendorLockIn = () => {
  return (
    <Row className={styles.row}>
      <section className={styles.snapComponents}>
        <h2 className={styles.snapComponentsTitle}>No Vendor Lock-In!</h2>
        <Column.Wrap className={styles.snapComponentsRow}>
          <Column
            title="Code Export"
            icon={CodeExportIcon}
            description={
              <>
                You are Free to leave the platform without any loss of work by
                converting your nocode app to code by a simple click of the
                button!
              </>
            }
          />
          <Column
            title="Built-in Code Editor"
            icon={BuiltInEditorIcon}
            description={
              <>
                A full blown code editor inside Canonic bypasses any limitation
                of the UI. You are in full control and can change the working of
                your app as required.
              </>
            }
          />
          <Column
            title="Self-Hosted"
            icon={SelfHostedIcon}
            description={
              <>
                Deploy Canonic on your own infrastructure in less than 10
                minutes and access your data no matter where it is. Start
                shipping apps fast and securely to move your business forward.
              </>
            }
          />
        </Column.Wrap>
      </section>
    </Row>
  )
}

export default NoVendorLockIn
