import React from "react"
import Link from "gatsby-plugin-transition-link"
import getAppUrl from "@src/utils/getAppUrl"
import Row from "../../components/Row"
import * as styles from "./Splash.module.scss"

const Splash = ({ location }) => {
  const appUrl = getAppUrl.hook(location)
  return (
    <Row className={styles.row}>
      <section className={styles.splash}>
        <div className={styles.splashText}>
          <h1 className={styles.splashTextTitle}>
            The simplest way for agile teams to build internal tools.
          </h1>
          <p className={styles.splashTextSubtitle}>
            A low-code platform for teams to focus on critical tasks and
            automate the rest.
          </p>
          <div className={styles.splashTextCtas}>
            <a href={appUrl} target="_blank" rel="noopener noreferrer">
              <button className={styles.splashTextCtasButton}>
                Try for free
              </button>
            </a>
            <Link
              className={styles.splashTextCtasLink}
              to={"get-a-demo"}
              entry={{ length: 0.3 }}
              exit={{ length: 0.3 }}
            >
              Get a live demo
            </Link>
          </div>
        </div>
        <div className={styles.splashIllustration}>
          <figure className={styles.useCasesPreview}>
            <div
              class="wistia_responsive_padding"
              style={{
                padding: "54.7% 0 0 0",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <div
                class="wistia_responsive_wrapper"
                style={{
                  height: "calc(100% + 6px)",
                  left: 0,
                  position: "absolute",
                  top: 0,
                  width: "100%",
                }}
              >
                <iframe
                  src={`https://fast.wistia.net/embed/iframe/zy9qjmaomh?videoFoam=true`}
                  // src={`https://fast.wistia.net/embed/iframe/zy9qjmaomh`}
                  title="Workflows Video"
                  allow="autoplay; fullscreen"
                  allowtransparency="true"
                  frameborder="0"
                  scrolling="no"
                  class="wistia_embed"
                  name="wistia_embed"
                  msallowfullscreen
                  width="100%"
                  height="100%"
                ></iframe>
              </div>
            </div>
            <script
              src="https://fast.wistia.net/assets/external/E-v1.js"
              async
            ></script>
          </figure>
        </div>
      </section>
    </Row>
  )
}

export default Splash
